/* eslint-disable no-nested-ternary */
import React, { Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { RootState } from '../store/create-store';
import Layout from '../common/components/layout/layout.component';
import NewHome from './new/container/login.container';
import Loader from '../common/components/loader/loader.component';
import CustomSnackbar from '../common/components/custom-snackbar/custom-snackbar.component';
import CookiePopUp from '../common/components/accept-cookies/cookie-popup.component';
import QuickTourHome from './quick-tour/components/home.component';
import { useUserData } from '../common/contexts/user-data.context';
import InvestorContainer from './investors/investors.container';

const Home = lazy(() => import('./home/home.index'));
const CookiePolicy = lazy(
  () => import('./cookie-policy/container/cookie-policy.component'),
);
const TrustPolicy = lazy(
  () => import('./trust-policy/container/trust-policy.component'),
);
const TermsAndConditions = lazy(
  () =>
    import('./terms-and-conditions/container/terms-and-conditions.container'),
);
const PotentialCalculator2 = lazy(
  () => import('./potential-calculator-2/potential-calculator-2.component'),
);
const QuickTour = lazy(() => import('./quick-tour/quick-tour.component'));
const UnsubscribePromotionalEmails = lazy(
  () =>
    import(
      './unsubscribe-promotional-emails/unsubscribe-promotional-email.component'
    ),
);
const QuickStart = lazy(() => import('./quick-start/quickstart'));
const PrivacyPolicy = lazy(
  () => import('./privacy-policy/container/privacy-policy.container'),
);
const AboutUs = lazy(() => import('./about-us/container/about-us.container'));
const VerifyEmail = lazy(
  () => import('./home/routes/verify/verify-email.component'),
);
const ShareWizard = lazy(() => import('./share-wizard/share-wizard.component'));
const Onboarding = lazy(
  () => import('./onboarding/container/onboarding.container'),
);

function AppInternalRoutes(): JSX.Element {
  return (
    <Layout>
      <Suspense fallback={<Loader showLoader />}>
        <Switch>
          <Route component={Home} />
        </Switch>
      </Suspense>
    </Layout>
  );
}

export default function AppRoutes(): JSX.Element {
  const commonState = useSelector((state: RootState) => state.common);
  const { showLoader } = commonState;
  const { auth, isLoggedIn } = useUserData();
  return (
    <>
      <Suspense fallback={<Loader showLoader />}>
        <Switch>
          <Route
            exact
            path="/terms-and-conditions"
            component={TermsAndConditions}
          />
          <Route
            exact
            path="/terms-and-condition"
            render={() => <Redirect to="/terms-and-conditions" />}
          />
          <Route exact path="/about-us" component={AboutUs} />
          <Route exact path="/investors" component={InvestorContainer} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/cookie-policy" component={CookiePolicy} />
          <Route exact path="/trust-policy" component={TrustPolicy} />
          <Route path="/p-calculator-2" component={PotentialCalculator2} />
          <Route path="/share-wizard" component={ShareWizard} />
          <Route path="/quick-tour" component={QuickTour} />
          <Route path="/quick-tour-home" component={QuickTourHome} />
          <Route path="/verify" component={VerifyEmail} />
          <Route
            exact
            path="/unsubscribe"
            component={UnsubscribePromotionalEmails}
          />
          <Route
            path="/redirect"
            render={() => <Redirect to="/u/dashboard" />}
          />
          <Route path="/quickstart" component={QuickStart} />
          <Route path="/onboarding" component={Onboarding} />
          <Route path="/share-wizard" component={ShareWizard} />
          <Route path="/u" component={AppInternalRoutes} />
          <Route
            exact={!isLoggedIn}
            path="/:referrerChannelId?"
            component={NewHome}
          />
          <Route render={() => <Redirect to="/" />} />
        </Switch>
      </Suspense>
      <CustomSnackbar />
      {!auth && <CookiePopUp />}
      <Loader showLoader={showLoader} />
    </>
  );
}
